import { Link, graphql } from "gatsby";
import React, { useContext, useMemo } from "react";
import { css } from "styled-components";

import FilterContext from "../context/FilterContext";
import { addPlaceDistances, filterPlaces } from "../util/places";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Places from "../components/Places";
import Filter from "../components/Filter";

const pageStyles = css`
  .delivery-note {
    font-size: var(--typeScaleSmall);
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .placesGrid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 60px 20px;
    margin-top: 40px;
  }

  @media all and (min-width: 800px) {
    .placesGrid {
      grid-template-columns: repeat(2, minmax(350px, 1fr));

      .placeWrapper {
        max-width: 500px;
      }
    }
  }
`;

export const query = graphql`
  query($name: String!) {
    tag(name: { eq: $name }) {
      name
      count
      meta {
        header
        title
        description
      }
    }
    serviceTag(name: { eq: $name }) {
      name
      count
      meta {
        header
        title
        description
      }
    }
    allPlace(filter: { tags: { in: [$name] } }) {
      nodes {
        ...placeFragment
      }
    }
  }
`;

function TagPage(props) {
  const tag = props.data.tag || props.data.serviceTag;
  const places = props.data.allPlace.nodes;

  const description =
    tag.meta.description ||
    `Need ${tag.name}? Find Hamilton retaurants near you offering takeout and delivery. `;

  const header = tag.meta.header || `Hamilton Restaurants with ${tag.name}`;

  const {
    currentTags,
    userPosition,
    isHidingClosedLocations,
    distanceRestriction,
  } = useContext(FilterContext);

  const placesWithDistance = useMemo(
    () => addPlaceDistances({ places: places, userPosition }),
    [places, userPosition]
  );

  const filteredPlaces = useMemo(
    () =>
      filterPlaces({
        currentTags,
        distanceRestriction,
        isHidingClosedLocations,
        places: placesWithDistance,
      }),
    [
      currentTags,
      distanceRestriction,
      isHidingClosedLocations,
      placesWithDistance,
    ]
  );

  return (
    <Layout>
      <SEO title={tag.meta.title || tag.name} description={description} />
      <div id="top" css={pageStyles}>
        <Filter places={filteredPlaces} />

        <section>
          <h1>{header}</h1>

          <p>
            Own a restaurant? Your favourite spot missing from the list?{" "}
            <Link to="/new-business">Submissions are welcome</Link>.
            <br />
            Have any feedback or suggestions?{" "}
            <Link to="/feedback">Get in touch</Link>.
          </p>

          <Places places={filteredPlaces} currentTags={currentTags}></Places>
        </section>
      </div>
    </Layout>
  );
}
export default TagPage;
